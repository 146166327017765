import React, { useEffect, useState } from 'react'
import useConfig from 'store/ConfigProvider'
import { Cart } from './components/Cart'
import PhoneBox from './components/PhoneBox'
import ProfileBox from './components/ProfileBox'
import SearchBox from './components/SearchBox'
import Categories from 'components/Widgets/Categories'
import { MobileAndTabletView } from '../DeviceDetect'
import { Link } from 'react-router-dom'

const Header = () => {
  const { settings, categories } = useConfig()
  const [chatOpened, setChatOpened] = useState(false)

  return (
    <div className="header" style={{ display: chatOpened ? 'none' : 'block' }}>
      <div className="header-top">
        <MobileAndTabletView>
          <Categories categories={categories} mobileTop={true} />
        </MobileAndTabletView>
        <Link className="logo" to="/">
          <img
            src="/img/cosmo.png"
            width="50"
            height="50"
            alt="logo"
          />
        </Link>
        <SearchBox type="desktop" />
        <div className="header-info">
          <PhoneBox number={settings.phone_number} />
          <Cart />
          <ProfileBox />
        </div>
      </div>
      <SearchBox type="mobile" />
    </div>
  )
}

export default Header
